import { FilterTypes } from '../components/BookingCalendar/ViewModel/filterViewModel/filterViewModel';

export type Optional<T> = T | undefined;

export type FilterOptions = {
  [key in keyof typeof FilterTypes]: string[];
};

export enum SettingsTab {
  Manage = 'manage',
  Layout = 'layout',
  Display = 'display',
  Design = 'design',
  Text = 'text',
  Support = 'support',
}

// TODO
export enum HelpArticleIds {
  Manage = '',
  Layout = '',
  Display = '',
  Design = '',
  Text = '',
  Support = '',
}

export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  STRETCH = 'stretch',
}
