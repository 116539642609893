import { Booking } from '@wix/ambassador-bookings-server/types';
import { CatalogData, Service, ServiceType } from '@wix/bookings-uou-types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
  Slot,
} from '@wix/ambassador-availability-calendar/types';
import {
  getEndOfDayFromLocalDateTime,
  getEndOfMonth,
  getLocalDateTimeStartOfDay,
  getTodayLocalDateTimeStartOfDay,
} from '../utils/dateAndTime/dateAndTime';
import { createDummyCatalogData } from './dummyData/dummyCatalogData';
import { createDummySlots } from './dummyData/dummySlotsData';
import { createDummyDateAvailability } from './dummyData/dummyDateAvailability';
import { BookingsApi } from './BookingsApi';
import { CalendarApiInitParams, SlotsAvailabilityFilter } from './types';
import { Optional } from '../types/types';
import { CalendarErrors } from '../utils/bi/consts';
import { EmptyStateType } from '../components/BookingCalendar/ViewModel/emptyStateViewModel/emptyStateViewModel';
import settingsParams from '../components/BookingCalendar/settingsParams';
import { CalendarState } from '../components/BookingCalendar/controller';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { AddError } from '../components/BookingCalendar/Actions/addError/addError';
import { getOnlyFutureSlotAvailabilities } from '../utils/timeSlots/timeSlots';

export const CALENDAR_PAGE_URL_PATH_PARAM = 'booking-calendar';

export class CalendarApi {
  private wixSdkAdapter: WixOOISDKAdapter;
  private bookingsApi: BookingsApi;
  private readonly reportError: ControllerParams['flowAPI']['reportError'];

  constructor({ wixSdkAdapter, reportError }: CalendarApiInitParams) {
    this.wixSdkAdapter = wixSdkAdapter;
    this.reportError = reportError;
    this.bookingsApi = new BookingsApi({
      authorization: wixSdkAdapter.getInstance(),
      baseUrl: wixSdkAdapter.getServerBaseUrl(),
    });
  }

  async getCatalogData({
    onError,
  }: {
    onError: (type: EmptyStateType) => void;
  }): Promise<Optional<CatalogData>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      const catalogData = await this.bookingsApi.getCatalogData({
        servicesOptions: {
          include: false,
        },
        resourcesOptions: {
          include: false,
        },
      });
      const dummyCatalogData = createDummyCatalogData();
      dummyCatalogData.businessInfo = catalogData.businessInfo;
      return dummyCatalogData;
    }

    const serviceSlug = await this.wixSdkAdapter.getServiceSlug(
      CALENDAR_PAGE_URL_PATH_PARAM,
    );
    const resourceSlug = this.getResourceSlug();

    try {
      const catalogData: CatalogData = await this.bookingsApi.getCatalogData({
        servicesOptions: {
          slug: serviceSlug,
          include: true,
        },
        resourcesOptions: {
          slug: resourceSlug,
          include: !!resourceSlug,
        },
      });

      const {
        services: [service],
      } = catalogData;

      if (!service) {
        onError(EmptyStateType.SERVICE_NOT_FOUND);
        return;
      }

      if (!this.isCalendarFlow(service)) {
        await this.wixSdkAdapter.navigateToBookingsServicePage(serviceSlug);
        return;
      }

      return catalogData;
    } catch (e) {
      this.reportError(e);
      onError(EmptyStateType.SERVER_ERROR);
    }
  }

  async getNextAvailableDate(
    slotsAvailabilityFilter: SlotsAvailabilityFilter,
    onError: AddError,
  ) {
    // or todo
    // async getNextAvailableDate(fromLocalDateTime: string,
    //     { state, settings }: { state: CalendarState; settings: any }) {
    //
    // const to = fromLocalDateTime + 3 month;
    // const availabilityCalendarRequest: QueryAvailabilityRequest = this.buildQueryAvailabilityRequest(
    //   fromLocalDateTime,
    //   to,
    //   { state, settings },
    // );
    // const slotsAvailability = this.bookingsApi.getSlotsAvailability(availabilityCalendarRequest);

    try {
      const dateAvailabilityResponse = await this.getDateAvailabilityToRemove(
        slotsAvailabilityFilter,
      );
      const nextAvailableDate = dateAvailabilityResponse.nextAvailable?.date;
      if (nextAvailableDate) {
        return getLocalDateTimeStartOfDay(nextAvailableDate);
      }

      onError(CalendarErrors.NO_NEXT_AVAILABLE_DATE);
    } catch (e) {
      this.reportError(e);
      onError(CalendarErrors.NEXT_AVAILABLE_DATE_SERVER_ERROR);
    }
  }

  async getDateAvailabilityToRemove(
    slotsAvailabilityFilter: SlotsAvailabilityFilter,
  ) {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyDateAvailability();
    }

    try {
      return await this.bookingsApi.getDateAvailability(
        slotsAvailabilityFilter,
      );
    } catch (e) {
      this.reportError(e);
    }
  }

  async getDateAvailability(
    startOfMonthAsLocalDateTime: string,
    { state, settings }: { state: CalendarState; settings: any },
  ) {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummyDateAvailability();
    }

    try {
      let from;
      const to = getEndOfMonth(startOfMonthAsLocalDateTime);
      const { selectedTimezone } = state;
      const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(
        selectedTimezone!,
      );
      if (new Date(to) < new Date(todayLocalDateTime)) {
        return {};
      } else {
        from =
          new Date(todayLocalDateTime) > new Date(startOfMonthAsLocalDateTime)
            ? todayLocalDateTime
            : startOfMonthAsLocalDateTime;
      }
      const shouldLimitPerDay = true;
      const availabilityCalendarRequest: QueryAvailabilityRequest = this.buildQueryAvailabilityRequest(
        from,
        to,
        { state, settings },
        shouldLimitPerDay,
      );

      return await this.bookingsApi.getSlotsAvailability(
        availabilityCalendarRequest,
      );
    } catch (e) {
      this.reportError(e);
    }
  }

  async getSlotsForSelectedDate(
    selectedLocalDateTime: string,
    {
      state,
      settings,
      onError,
    }: {
      state: CalendarState;
      settings: any;
      onError: AddError;
    },
  ): Promise<Optional<QueryAvailabilityResponse>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return createDummySlots();
    }
    try {
      const to = getEndOfDayFromLocalDateTime(selectedLocalDateTime);
      const availabilityCalendarRequest: QueryAvailabilityRequest = this.buildQueryAvailabilityRequest(
        selectedLocalDateTime,
        to,
        { state, settings },
      );

      const slotAvailability = await this.bookingsApi.getSlotsAvailability(
        availabilityCalendarRequest,
      );

      return {
        ...slotAvailability,
        availabilityEntries: getOnlyFutureSlotAvailabilities(slotAvailability),
      };
    } catch (e) {
      this.reportError(e);
      onError(CalendarErrors.AVAILABLE_SLOTS_SERVER_ERROR);
    }
  }

  async getBookingDetails({
    onError,
  }: {
    onError: (type: EmptyStateType) => void;
  }): Promise<Optional<Booking>> {
    const bookingId = this.wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.BOOKING_ID,
    );
    if (!bookingId || this.wixSdkAdapter.isSSR()) {
      return;
    }

    try {
      return await this.bookingsApi.getBookingDetails(bookingId);
    } catch (e) {
      this.reportError(e);
      const errorType =
        e?.httpStatus === 403
          ? EmptyStateType.GET_BOOKING_DETAILS_ACCESS_DENIED
          : EmptyStateType.GET_BOOKING_DETAILS_ERROR;
      onError(errorType);
    }
  }

  async rescheduleBooking({
    bookingId,
    service,
    slot,
    timezone,
    onError,
  }: {
    bookingId: string;
    service: Service;
    slot: Slot;
    timezone: string;
    onError: AddError;
  }) {
    try {
      if (this.isServiceAClass(service)) {
        return this.bookingsApi.rescheduleClassBooking({
          bookingId,
          sessionId: slot.id!,
        });
      } else {
        return this.bookingsApi.rescheduleAppointmentBooking({
          bookingId,
          scheduleId: slot.scheduleId!,
          timezone,
          start: slot.start!,
          end: slot.end!,
          staffMembersScheduleIds: [slot.resource!.scheduleId!],
          location: slot.location,
        });
      }
    } catch (e) {
      this.reportError(e);
      onError(CalendarErrors.RESCHEDULE_SERVER_ERROR);
    }
  }

  private isServiceAClass = (service: Service) =>
    service.info.type === ServiceType.GROUP;

  private isCalendarFlow = (service: Service) =>
    service.info.type !== ServiceType.COURSE &&
    service.policy.isBookOnlineAllowed;

  private getResourceSlug() {
    const staffQueryParam = this.wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.STAFF,
    );

    if (staffQueryParam) {
      if (Array.isArray(staffQueryParam)) {
        return staffQueryParam[0];
      } else {
        return staffQueryParam;
      }
    }
  }

  private buildQueryAvailabilityRequest(
    fromLocalDateTime: string,
    to: string,
    { state, settings }: { state: CalendarState; settings: any },
    shouldLimitPerDay = false,
  ) {
    const { selectedTimezone, filterOptions, selectedService } = state;
    const onlyAvailableSlots = settings.get(settingsParams.onlyAvailableSlots);
    const availabilityCalendarRequest: QueryAvailabilityRequest = {
      timezone: selectedTimezone,
      ...(shouldLimitPerDay ? { limitPerDay: 1 } : {}),
      query: {
        filter: {
          serviceIds: [`${selectedService?.id}`],
          from: fromLocalDateTime,
          to,
          ...(onlyAvailableSlots === true
            ? { isBookable: onlyAvailableSlots }
            : {}),
          ...(filterOptions.STAFF_MEMBER?.length > 0
            ? { resourceId: filterOptions.STAFF_MEMBER }
            : {}),
          ...(filterOptions.LOCATION?.length > 0
            ? { 'location.businessLocation.id': filterOptions.LOCATION }
            : {}),
        },
      },
    };
    return availabilityCalendarRequest;
  }
}
