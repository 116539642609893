import { CalendarErrors, Preference } from '../bi/consts';
import settingsParams from '../../components/BookingCalendar/settingsParams';
import {
  LocationType,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { TFunction } from '../../components/BookingCalendar/controller';
import { CalendarContext } from '../context/contextFactory';
import { Optional } from '../../types/types';
import { getSlotDuration } from '../duration/duration';

export type BookingsPreferenceError = {
  key: CalendarErrors;
  message: string;
};

export type SelectedBookingPreference = {
  key: Preference;
  value: string;
};

export type BookingPreferenceOption = {
  id?: string;
  value?: string;
  isSelectable?: boolean;
  isWithWaitingList?: boolean;
};

export type BookingPreference = {
  key: Preference;
  error: BookingsPreferenceError;
  options: BookingPreferenceOption[];
  placeholder: string;
  getBookingPreferenceOptionFromSlot?: (
    slotAvailability: SlotAvailability,
  ) => BookingPreferenceOption;
};

export const getBookingPreferences = ({
  dateRegionalSettingsLocale,
  t,
  settings,
}: {
  dateRegionalSettingsLocale: string;
  t: TFunction;
  settings: CalendarContext['settings'];
}): BookingPreference[] => {
  const locationLabel = settings.get(settingsParams.locationLabel);
  const location: BookingPreference = {
    key: Preference.LOCATION,
    error: {
      key: CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION,
      message: t('app.booking-details.dropdowns.error.location.text', {
        location: locationLabel.toLowerCase(),
      }),
    },
    placeholder: locationLabel,
    options: [],
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ) => {
      const locationId = slotAvailability!.slot!.location!.id;
      const locationText = getLocationText(slotAvailability!.slot!.location, t);

      return {
        id: locationId || locationText!,
        value: locationText!,
      };
    },
  };

  const staffMemberLabel = settings.get(settingsParams.staffMemberLabel);
  const staffMember: BookingPreference = {
    key: Preference.STAFF_MEMBER,
    error: {
      key: CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER,
      message: t('app.booking-details.dropdowns.error.staff-member.text', {
        staff: staffMemberLabel.toLowerCase(),
      }),
    },
    placeholder: staffMemberLabel,
    options: [],
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ) => {
      const staffMemberName = slotAvailability.slot?.resource?.name!;
      const staffMemberId = slotAvailability.slot?.resource?.id!;

      return {
        id: staffMemberId,
        value: staffMemberName,
      };
    },
  };

  const durationLabel = settings.get(settingsParams.durationLabel);
  const duration: BookingPreference = {
    key: Preference.DURATION,
    error: {
      key: CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION,
      message: t('app.booking-details.dropdowns.error.duration.text', {
        duration: durationLabel.toLowerCase(),
      }),
    },
    placeholder: durationLabel,
    options: [],
    getBookingPreferenceOptionFromSlot: (
      slotAvailability: SlotAvailability,
    ) => {
      const rfcStartTime = slotAvailability.slot?.start!;
      const rfcEndTime = slotAvailability.slot?.end!;
      const slotDuration = getSlotDuration({
        rfcStartTime,
        rfcEndTime,
        t,
        dateRegionalSettingsLocale,
      });

      return {
        id: slotDuration,
        value: slotDuration,
      };
    },
  };

  return [location, staffMember, duration];
};

const getLocationText = (location: any, t: TFunction): Optional<string> => {
  switch (location?.locationType) {
    case LocationType.OWNER_BUSINESS:
      return location.name;
    case LocationType.OWNER_CUSTOM:
      return location.formattedAddress;
    case LocationType.CUSTOM:
      return t('app.booking-details.dropdowns.locations.client-place.text');
    default:
      return '';
  }
};
