import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';
import { AlignmentOptions } from '../../types/types';

export type ISettingsParams = {
  columnAlignment: SettingsParamType.String;
  textAlignment: AlignmentOptions;
  dateAndTimeSectionHeaderText: SettingsParamType.String;
  bookingDetailsSectionHeaderText: SettingsParamType.String;
  headerSubtitleVisibility: SettingsParamType.Boolean;
  headerSubtitleShouldBeServiceTagline: SettingsParamType.Boolean;
  headerSubtitleText: SettingsParamType.Text;
  timePickerNoSessionsOfferedText: SettingsParamType.Text;
  timePickerNoUpcomingTimeSlotsText: SettingsParamType.Text;
  timePickerGoToNextAvailableLinkText: SettingsParamType.Text;
  timePickerAllSessionsAreFullNotificationText: SettingsParamType.Text;
  timePickerShowAllButtonText: SettingsParamType.Text;
  timePickerShouldLimitNumberOfTimeSlotsDisplayedPerDay: SettingsParamType.Boolean;
  timePickerMaxNumberOfTimeSlotsDisplayedPerDay: SettingsParamType.Number;
  initializeWithFirstAvailableDate: SettingsParamType.Boolean;
  unavailableTimeSlotsVisibility: SettingsParamType.Boolean;
  videoConferenceBadgeText: SettingsParamType.Text;
  bookingDetailsPreferencesTitleText: SettingsParamType.Text;
  staffMemberLabel: SettingsParamType.Text;
  locationLabel: SettingsParamType.Text;
  durationLabel: SettingsParamType.Text;
  bookingDetailsPricingPlanText: SettingsParamType.Text;
  bookingDetailsClearText: SettingsParamType.Text;
  bookingDetailsBookNowText: SettingsParamType.Text;
  bookingDetailsPendingApprovalText: SettingsParamType.Text;
  bookingDetailsJoinWaitlistText: SettingsParamType.Text;
  bookingDetailsRescheduleText: SettingsParamType.Text;
  onlyAvailableSlots: SettingsParamType.Boolean;
  paymentSectionTitle: SettingsParamType.String;
  waitlistIndicationText: SettingsParamType.String;
  buttonsFullWidth: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  columnAlignment: {
    getDefaultValue: (): AlignmentOptions => AlignmentOptions.CENTER,
  },
  textAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  dateAndTimeSectionHeaderText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.widget.date-and-time-header'),
  },
  bookingDetailsSectionHeaderText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.widget.booking-details-header'),
  },
  headerSubtitleVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerSubtitleShouldBeServiceTagline: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerSubtitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.header-subtitle'),
  },
  timePickerNoSessionsOfferedText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.time-picker.no-slots-message'),
  },
  timePickerNoUpcomingTimeSlotsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.time-picker.notifications.no-upcoming-time-slots'),
  },
  timePickerGoToNextAvailableLinkText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.time-picker.go-to-next-available-day'),
  },
  timePickerAllSessionsAreFullNotificationText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t(
        'app.settings.defaults.time-picker.notifications.all-sessions-are-full',
      ),
  },
  timePickerShowAllButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.time-picker.show-all'),
  },
  timePickerShouldLimitNumberOfTimeSlotsDisplayedPerDay: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  timePickerMaxNumberOfTimeSlotsDisplayedPerDay: {
    type: SettingsParamType.Number,
    getDefaultValue: () => 10,
  },
  bookingDetailsPreferencesTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.preferences.title'),
  },
  staffMemberLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.staff-member-label'),
  },
  locationLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.location-label'),
  },
  durationLabel: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.duration-label'),
  },
  bookingDetailsPricingPlanText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pricing-plan.title'),
  },
  bookingDetailsClearText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.clear.title'),
  },
  bookingDetailsBookNowText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.book-now.text'),
  },
  bookingDetailsRescheduleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.rescheduled-booking.booking-details.cta'),
  },
  bookingDetailsPendingApprovalText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.pending-approval.text'),
  },
  bookingDetailsJoinWaitlistText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.join-waitlist.text'),
  },
  unavailableTimeSlotsVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  initializeWithFirstAvailableDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  videoConferenceBadgeText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.video-conference-badge-text'),
  },
  onlyAvailableSlots: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  paymentSectionTitle: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.settings.defaults.booking-details.payment.title'),
  },
  waitlistIndicationText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('app.settings.defaults.waitlist'),
  },
  buttonsFullWidth: {
    type: SettingsParamType.Boolean,
    inheritDesktop: false,
    getDefaultValue: () => true,
  },
});
