import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  OfferedAsType,
  ServicePayment,
  ServicePaymentDto,
} from '@wix/bookings-uou-types';
import {
  PaymentDtoMapper,
  isServiceOfferedAsPricingPlan,
} from '@wix/bookings-uou-mappers';
import { formatRfcTimeStringToDateAndTimeView } from '../../../../utils/dateAndTime/dateAndTime';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState, TFunction } from '../../controller';
import settingsParams from '../../settingsParams';
import {
  BookingPreference,
  BookingPreferenceOption,
} from '../../../../utils/bookingPreferences/bookingPreferences';
import { CalendarErrors, Preference } from '../../../../utils/bi/consts';
import { isWaitingListFlow } from '../../../../utils/waitingList/waitingList';

export type BookingDetailsPreferences = {
  bookingPreferences: BookingPreference[];
  titleText: string;
  clearText: string;
};

export type BookingDetailsViewModel = {
  serviceName: string;
  dateAndTime?: string;
  paymentDescription?: string;
  videoConferenceBadgeText?: string;
  preferences: BookingDetailsPreferences;
  ctaText: string;
  ctaFullWidth: boolean;
};

export async function createBookingDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Promise<BookingDetailsViewModel> {
  const { businessInfo, t, settings, wixSdkAdapter } = context;
  const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
  const {
    selectedService,
    selectableSlotsAtSelectedTime,
    selectedTime,
    selectedBookingPreferences,
    calendarErrors,
  } = state;

  const serviceName = selectedService.info.name;

  const videoConferenceBadgeText = selectedService.videoConferenceProviderId
    ? settings.get(settingsParams.videoConferenceBadgeText)
    : '';

  const paymentDescription = await getPaymentDescription({
    wixSdkAdapter,
    payment: selectedService.payment,
    dateRegionalSettingsLocale,
    bookingDetailsPricingPlanText: settings.get(
      settingsParams.bookingDetailsPricingPlanText,
    ),
  });

  const dateAndTime = selectedTime
    ? formatRfcTimeStringToDateAndTimeView(
        selectedTime,
        dateRegionalSettingsLocale,
      )
    : '';
  const bookingPreferences = getBookingPreferencesForSelectedTime({
    selectableSlotsAtSelectedTime: selectableSlotsAtSelectedTime ?? [],
    calendarErrors,
    t,
    settings,
    dateRegionalSettingsLocale,
    selectedBookingPreferences,
  });

  const ctaText = getCtaText({ state, settings, bookingPreferences });

  return {
    serviceName,
    paymentDescription,
    dateAndTime,
    videoConferenceBadgeText,
    preferences: {
      bookingPreferences: getBookingPreferencesOptionsWithEnrichedValue({
        bookingPreferences,
        waitlistIndicationText: settings.get(
          settingsParams.waitlistIndicationText,
        ),
        t,
      }),
      clearText: settings.get(settingsParams.bookingDetailsClearText),
      titleText: settings.get(
        settingsParams.bookingDetailsPreferencesTitleText,
      ),
    },
    ctaText,
    ctaFullWidth: settings.get(settingsParams.buttonsFullWidth),
  };
}

export function createDummyBookingDetailsViewModel({
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): BookingDetailsViewModel {
  const { t, settings } = context;
  return {
    serviceName: t('dummy-content.service.name'),
    paymentDescription: t('dummy-content.service.price'),
    ctaText: settings.get(settingsParams.bookingDetailsBookNowText),
    ctaFullWidth: settings.get(settingsParams.buttonsFullWidth),
    preferences: {
      bookingPreferences: [
        {
          error: {
            key: CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION,
            message: '',
          },
          key: Preference.DURATION,
          placeholder: '',
          options: [
            {
              value: t('dummy-content.service.duration'),
            },
          ],
        },
        {
          error: {
            key:
              CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER,
            message: '',
          },
          key: Preference.STAFF_MEMBER,
          placeholder: '',
          options: [
            {
              value: t('dummy-content.service.staff'),
            },
          ],
        },
        {
          error: {
            key: CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION,
            message: '',
          },
          key: Preference.LOCATION,
          placeholder: '',
          options: [
            {
              value: t('dummy-content.service.location'),
            },
          ],
        },
      ],
      clearText: settings.get(settingsParams.bookingDetailsClearText),
      titleText: settings.get(
        settingsParams.bookingDetailsPreferencesTitleText,
      ),
    },
  };
}

const getCtaText = ({
  state,
  bookingPreferences,
  settings,
}: {
  state: CalendarState;
  settings: any;
  bookingPreferences: BookingPreference[];
}): string => {
  const { selectableSlotsAtSelectedTime, selectedBookingPreferences } = state;
  const isRescheduling = isReschedulingFlow(state);
  const isPendingApproval = isPendingApprovalFlow(state);
  const isWaitingList = isWaitingListFlow({
    selectableSlots: selectableSlotsAtSelectedTime,
    selectedBookingPreferences,
    bookingPreferences,
  });
  if (isRescheduling) {
    return settings.get(settingsParams.bookingDetailsRescheduleText);
  }
  if (isPendingApproval) {
    return settings.get(settingsParams.bookingDetailsPendingApprovalText);
  }
  if (isWaitingList) {
    return settings.get(settingsParams.bookingDetailsJoinWaitlistText);
  }
  return settings.get(settingsParams.bookingDetailsBookNowText);
};

const isReschedulingFlow = (state: CalendarState) =>
  !!state.rescheduleBookingDetails;

const isPendingApprovalFlow = (state: CalendarState) =>
  !!state.selectedService?.policy?.isPendingApprovalFlow;

const getPaymentDescription = async ({
  wixSdkAdapter,
  payment,
  dateRegionalSettingsLocale,
  bookingDetailsPricingPlanText,
}: {
  wixSdkAdapter: WixOOISDKAdapter;
  payment: ServicePayment;
  dateRegionalSettingsLocale: string;
  bookingDetailsPricingPlanText: string;
}) => {
  const paymentDescription = [];

  const isServiceBookableWithPricingPlan = await wixSdkAdapter.isPricingPlanInstalled();
  if (isOfferedAsOneTime(payment)) {
    const priceText = getPriceText(payment, dateRegionalSettingsLocale);
    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan(payment, isServiceBookableWithPricingPlan)
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return paymentDescription.join(' | ');
};

const isOfferedAsOneTime = (payment: ServicePayment) => {
  return payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0;
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
) => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  return paymentDtoMapper.priceText(paymentDto);
};

const getBookingPreferencesOptionsWithEnrichedValue = ({
  bookingPreferences,
  waitlistIndicationText,
  t,
}: {
  bookingPreferences: BookingPreference[];
  waitlistIndicationText: string;
  t: TFunction;
}): BookingPreference[] => {
  return bookingPreferences.map((bookingPreference: BookingPreference) => {
    let options: BookingPreferenceOption[];
    if (bookingPreference.options.length > 1) {
      options = bookingPreference.options.map(
        (bookingPreferenceOption: BookingPreferenceOption) => {
          const valueWithWaitlistOnlyIndication = t(
            'app.booking-details.dropdowns.option-with-waitlist-only',
            {
              option: bookingPreferenceOption.value,
              waitlistOnly: waitlistIndicationText,
            },
          );
          return {
            ...bookingPreferenceOption,
            value: bookingPreferenceOption.isWithWaitingList
              ? valueWithWaitlistOnlyIndication
              : bookingPreferenceOption.value,
          };
        },
      );
    } else {
      options = bookingPreference.options;
    }

    return {
      ...bookingPreference,
      options,
    };
  });
};
